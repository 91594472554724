<template>
  <!-- Tasks-area -->
  <div class="tasks-area">
    <div class="accordion" id="accordionExample4">
      <div class="card">
        <div class="card-header" id="headingOne4">
          <div class="mb-0">
            <button
              class="btn btn-link"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne4"
            >
              <div class="detais-title">
                <h3>
                  <img
                    class="img-fluid arrow-down"
                    src="../../assets/img/arrow-down.svg"
                    alt=""
                  />
                  Tasks
                  <a
                    v-if="$route.name != 'Dashboard'"
                    class="add-appoi addtask"
                    href="#"
                    v-on:click.stop.prevent="toggleAddTask('show')"
                    ><img
                      class="img-fluid"
                      src="../../assets/img/24. Close.svg"
                      alt=""
                    />Add</a
                  >
                </h3>
              </div>
            </button>
            <!-- <p class="single_date_chooser">
                        <date-range-picker :locale-data="{ format: 'yyyy-mm-dd' }"  v-model="tskDate" :single-date-picker="true" :show-dropdowns="true" :auto-apply="true" :ranges="false" @update="getTasks('date')"></date-range-picker>
                        <span>{{tskDate.startDate | formatDate('MMMM DD, dddd')}} <img class="img-fluid calenders" src="../../assets/img/calendar.svg" alt=""></span>
                    </p> -->
          </div>
        </div>
        <div
          id="collapseOne4"
          :class="lTasks.length > 0 ? 'collapse show' : 'collapse'"
          aria-labelledby="headingOne4"
          data-parent="#accordionExample4"
        >
          <div class="card-body">
            <simplebar class="active-meeting" id="myElement2">
              <div class="media" v-for="task in lTasks" :key="task.ta_id">
                <!-- <div
                  class="media-body"
                  :class="
                    task.time_status == 'today'
                      ? aptColors[0]
                      : task.time_status == 'past'
                      ? aptColors[1]
                      : aptColors[2]
                  "
                > -->
                <div
                  class="media-body"
                  :class="
                    task.time_status == 'today'
                      ? aptColors[0]
                      : task.time_status == 'past'
                      ? aptColors[1]
                      : aptColors[2]
                  "
                >
                  <h5 class="mt-0" style="text-transform: unset">
                    {{ task.ta_name }}
                  </h5>
                  <p v-if="$storage.get('auth').user.user_type == 1">
                    Assigned to: {{ task.name }}
                  </p>
                  <p>{{ task.ta_date | formatDate("DD MMM hh:mm A") }}</p>
                  <div class="appo-menu">
                    <ul>
                      <li v-if="task.ta_task_type == 1">
                        <a
                          data-toggle="tooltip"
                          title="Completed"
                          :id="'compl' + task.ta_id"
                          class="okay"
                          v-on:click.stop.prevent="openModal(task.ta_id)"
                          :class="task.ta_status == 1 ? 'okay-bg' : ''"
                          href="#"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <g clip-path="url(#clip0)">
                              <path
                                d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z"
                                fill="#3151A1"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect width="10" height="10" fill="white" />
                              </clipPath>
                            </defs></svg
                        ></a>
                      </li>
                      <li v-else>
                        <a
                          data-toggle="tooltip"
                          title="Completed"
                          :id="'compl' + task.ta_id"
                          class="okay"
                          v-on:click.stop.prevent="
                            updateTaskStatus(task.ta_id, 1, task.time_status)
                          "
                          :class="task.ta_status == 1 ? 'okay-bg' : ''"
                          href="#"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <g clip-path="url(#clip0)">
                              <path
                                d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z"
                                fill="#3151A1"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect width="10" height="10" fill="white" />
                              </clipPath>
                            </defs></svg
                        ></a>
                      </li>
                      <li>
                        <a
                          data-toggle="tooltip"
                          title="Reschedule"
                          href="#"
                          v-on:click.stop.prevent="reScheduleTask(task)"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="10"
                            viewBox="0 0 10 10"
                            fill="none"
                          >
                            <g clip-path="url(#clip0)">
                              <path
                                d="M3.41634 1.25C3.41634 0.973858 3.19248 0.75 2.91634 0.75C2.6402 0.75 2.41634 0.973858 2.41634 1.25H3.41634ZM7.58301 1.25C7.58301 0.973858 7.35915 0.75 7.08301 0.75C6.80687 0.75 6.58301 0.973858 6.58301 1.25H7.58301ZM1.66634 2.58333H2.91634V1.58333H1.66634V2.58333ZM2.91634 2.58333H7.08301V1.58333H2.91634V2.58333ZM7.08301 2.58333H8.33301V1.58333H7.08301V2.58333ZM8.33301 9.08333H1.66634V10.0833H8.33301V9.08333ZM1.33301 8.75V4.16667H0.333008V8.75H1.33301ZM1.33301 4.16667V2.91667H0.333008V4.16667H1.33301ZM8.66634 2.91667V4.16667H9.66634V2.91667H8.66634ZM8.66634 4.16667V8.75H9.66634V4.16667H8.66634ZM0.833008 4.66667H9.16634V3.66667H0.833008V4.66667ZM3.41634 2.08333V1.25H2.41634V2.08333H3.41634ZM7.58301 2.08333V1.25H6.58301V2.08333H7.58301ZM1.66634 9.08333C1.48225 9.08333 1.33301 8.93409 1.33301 8.75H0.333008C0.333008 9.48638 0.929962 10.0833 1.66634 10.0833V9.08333ZM8.33301 10.0833C9.06939 10.0833 9.66634 9.48638 9.66634 8.75H8.66634C8.66634 8.9341 8.5171 9.08333 8.33301 9.08333V10.0833ZM8.33301 2.58333C8.5171 2.58333 8.66634 2.73257 8.66634 2.91667H9.66634C9.66634 2.18029 9.06939 1.58333 8.33301 1.58333V2.58333ZM1.66634 1.58333C0.929962 1.58333 0.333008 2.18029 0.333008 2.91667H1.33301C1.33301 2.73257 1.48225 2.58333 1.66634 2.58333V1.58333Z"
                                fill="#3151A1"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0">
                                <rect width="10" height="10" fill="white" />
                              </clipPath>
                            </defs></svg
                        ></a>
                      </li>
                      <li>
                        <a
                          data-toggle="tooltip"
                          title="Missed"
                          :id="'mis' + task.ta_id"
                          v-on:click.stop.prevent="
                            updateTaskStatus(task.ta_id, 2, task.time_status)
                          "
                          :class="task.ta_status == 2 ? 'notok-bg' : ''"
                          class="notok"
                          href="#"
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="8"
                            height="8"
                            viewBox="0 0 8 8"
                            fill="none"
                          >
                            <path
                              d="M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z"
                              fill="#3151A1"
                            /></svg
                        ></a>
                      </li>
                      <li v-if="task.ta_status == '0'">
                        <a
                          data-toggle="tooltip"
                          title="Delete"
                          :id="'delete' + task.ts_id"
                          v-on:click.stop.prevent="deleteTask(task.ta_id)"
                          href="#"
                          ><img
                            style="width: 11px; margin-top: 5px"
                            src="../../assets/img/Delete.svg"
                        /></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="media not-found" v-if="lTasks.length == 0">
                No Task Found.
              </div>
            </simplebar>

            <!-- <simplebar class="active-meeting" id="myElement2">
                    <div class="media" v-for="task in lTasks" :key="task.ta_id">
                        <div class="media-body" :class="task.time_status == 'today' ? aptColors[0]: task.time_status == 'past' ? aptColors[1]:aptColors[2]">
                            <p>{{task.ta_name}}</p>
                            <p>Scheduled for {{task.ta_date | formatDate('DD MMM')}}</p>
                            <p v-if="$storage.get('auth').user.user_type == 1">Assigned to {{task.name}}</p>
                            <div class="appo-menu">
                                 <ul>
                                    <li><a data-toggle="tooltip" title="Completed" :id="'compl'+task.ta_id" class="okay" v-on:click.stop.prevent="updateTaskStatus(task.ta_id,1,task.time_status)" :class="task.ta_status == 1 ? 'okay-bg':''" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><g clip-path="url(#clip0)"><path d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z" fill="#3151A1"/></g><defs><clipPath id="clip0"><rect width="10" height="10" fill="white"/></clipPath></defs></svg></a></li>
                                    <li><a data-toggle="tooltip" title="Reschedule" href="#" v-on:click.stop.prevent="reScheduleTask(task)" ><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><g clip-path="url(#clip0)"><path d="M3.41634 1.25C3.41634 0.973858 3.19248 0.75 2.91634 0.75C2.6402 0.75 2.41634 0.973858 2.41634 1.25H3.41634ZM7.58301 1.25C7.58301 0.973858 7.35915 0.75 7.08301 0.75C6.80687 0.75 6.58301 0.973858 6.58301 1.25H7.58301ZM1.66634 2.58333H2.91634V1.58333H1.66634V2.58333ZM2.91634 2.58333H7.08301V1.58333H2.91634V2.58333ZM7.08301 2.58333H8.33301V1.58333H7.08301V2.58333ZM8.33301 9.08333H1.66634V10.0833H8.33301V9.08333ZM1.33301 8.75V4.16667H0.333008V8.75H1.33301ZM1.33301 4.16667V2.91667H0.333008V4.16667H1.33301ZM8.66634 2.91667V4.16667H9.66634V2.91667H8.66634ZM8.66634 4.16667V8.75H9.66634V4.16667H8.66634ZM0.833008 4.66667H9.16634V3.66667H0.833008V4.66667ZM3.41634 2.08333V1.25H2.41634V2.08333H3.41634ZM7.58301 2.08333V1.25H6.58301V2.08333H7.58301ZM1.66634 9.08333C1.48225 9.08333 1.33301 8.93409 1.33301 8.75H0.333008C0.333008 9.48638 0.929962 10.0833 1.66634 10.0833V9.08333ZM8.33301 10.0833C9.06939 10.0833 9.66634 9.48638 9.66634 8.75H8.66634C8.66634 8.9341 8.5171 9.08333 8.33301 9.08333V10.0833ZM8.33301 2.58333C8.5171 2.58333 8.66634 2.73257 8.66634 2.91667H9.66634C9.66634 2.18029 9.06939 1.58333 8.33301 1.58333V2.58333ZM1.66634 1.58333C0.929962 1.58333 0.333008 2.18029 0.333008 2.91667H1.33301C1.33301 2.73257 1.48225 2.58333 1.66634 2.58333V1.58333Z" fill="#3151A1"/></g><defs><clipPath id="clip0"><rect width="10" height="10" fill="white"/></clipPath></defs></svg></a></li>
                                    <li><a data-toggle="tooltip" title="Missed" :id="'mis'+task.ta_id" v-on:click.stop.prevent="updateTaskStatus(task.ta_id,2,task.time_status)" :class="task.ta_status == 2 ? 'notok-bg':''" class="notok" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none"><path d="M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z" fill="#3151A1"/></svg></a></li>
                                    <li v-if="task.ta_status == '0'"><a data-toggle="tooltip" title="Delete" :id="'delete'+task.ts_id" v-on:click.stop.prevent="deleteTask(task.ta_id)" href="#"><img style="width: 11px;margin-top: 5px;" src="../../assets/img/Delete.svg" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="media not-found" v-if="lTasks.length == 0">No Task Found.</div>
                </simplebar> -->

            <!-- <simplebar class="all-task">
                    <div class="media" v-for="task in lTasks" :key="task.ta_id">
                        <p>
                        <label class="check2">
                            <input type="checkbox">
                            <span class="checkmarks"></span>
                        </label>
                        </p>
                        <div class="media-body">
                            <h5 class="mt-0">{{task.ta_name}}</h5>
                            <p>Scheduled for {{task.ta_date | formatDate('DD MMM')}} <a href="#" v-if="$storage.get('auth').user.user_type == 1">Assigned to {{task.name}}</a></p>
                            <div class="appo-menu">
                                <ul>
                                    <li><a data-toggle="tooltip" title="Completed" :id="'compl'+task.ta_id" class="okay" v-on:click.stop.prevent="updateTaskStatus(task.ta_id,1,task.time_status,task.ta_task_type)" :class="task.ta_status == 1 ? 'okay-bg':''" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><g clip-path="url(#clip0)"><path d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z" fill="#3151A1"/></g><defs><clipPath id="clip0"><rect width="10" height="10" fill="white"/></clipPath></defs></svg></a></li>
                                    <li><a data-toggle="tooltip" title="Reschedule" href="#" v-on:click.stop.prevent="reScheduleTask(task)" ><svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none"><g clip-path="url(#clip0)"><path d="M3.41634 1.25C3.41634 0.973858 3.19248 0.75 2.91634 0.75C2.6402 0.75 2.41634 0.973858 2.41634 1.25H3.41634ZM7.58301 1.25C7.58301 0.973858 7.35915 0.75 7.08301 0.75C6.80687 0.75 6.58301 0.973858 6.58301 1.25H7.58301ZM1.66634 2.58333H2.91634V1.58333H1.66634V2.58333ZM2.91634 2.58333H7.08301V1.58333H2.91634V2.58333ZM7.08301 2.58333H8.33301V1.58333H7.08301V2.58333ZM8.33301 9.08333H1.66634V10.0833H8.33301V9.08333ZM1.33301 8.75V4.16667H0.333008V8.75H1.33301ZM1.33301 4.16667V2.91667H0.333008V4.16667H1.33301ZM8.66634 2.91667V4.16667H9.66634V2.91667H8.66634ZM8.66634 4.16667V8.75H9.66634V4.16667H8.66634ZM0.833008 4.66667H9.16634V3.66667H0.833008V4.66667ZM3.41634 2.08333V1.25H2.41634V2.08333H3.41634ZM7.58301 2.08333V1.25H6.58301V2.08333H7.58301ZM1.66634 9.08333C1.48225 9.08333 1.33301 8.93409 1.33301 8.75H0.333008C0.333008 9.48638 0.929962 10.0833 1.66634 10.0833V9.08333ZM8.33301 10.0833C9.06939 10.0833 9.66634 9.48638 9.66634 8.75H8.66634C8.66634 8.9341 8.5171 9.08333 8.33301 9.08333V10.0833ZM8.33301 2.58333C8.5171 2.58333 8.66634 2.73257 8.66634 2.91667H9.66634C9.66634 2.18029 9.06939 1.58333 8.33301 1.58333V2.58333ZM1.66634 1.58333C0.929962 1.58333 0.333008 2.18029 0.333008 2.91667H1.33301C1.33301 2.73257 1.48225 2.58333 1.66634 2.58333V1.58333Z" fill="#3151A1"/></g><defs><clipPath id="clip0"><rect width="10" height="10" fill="white"/></clipPath></defs></svg></a></li>
                                    <li><a data-toggle="tooltip" title="Missed" :id="'mis'+task.ta_id" v-on:click.stop.prevent="updateTaskStatus(task.ta_id,2,task.time_status,task.ta_task_type)" :class="task.ta_status == 2 ? 'notok-bg':''" class="notok" href="#"><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none"><path d="M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z" fill="#3151A1"/></svg></a></li>
                                    <li v-if="task.ta_status == '0'"><a data-toggle="tooltip" title="Delete" :id="'delete'+task.ts_id" v-on:click.stop.prevent="deleteTask(task.ta_id)" href="#"><img style="width: 11px;margin-top: 5px;" src="../../assets/img/Delete.svg" /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="media not-found" v-if="lTasks.length == 0">No Task Found.</div>
                </simplebar> -->
          </div>
        </div>
      </div>
    </div>

    <!-- add-Tasks -->
    <div class="add-tasks add-task">
      <div class="modal-header">
        <h5 class="modal-title">Add Task</h5>
        <a
          v-if="this.$store.state.contacted == false"
          class="close hide-task"
          data-dismiss="modal"
          aria-label="Close"
          v-on:click.stop.prevent="toggleAddTask('hide')"
        >
          <span aria-hidden="true"
            ><img
              class="img-fluid"
              src="../../assets/img/23. Closex.svg"
              alt=""
          /></span>
        </a>
      </div>
      <form v-on:submit.prevent="addNewTask">
        <div class="add-body">
          <div class="form-group">
            <label for="inputAddress2">Task type</label>
            <select
              class="form-control js-example-tags"
              v-model="addTask.type"
              @change="onChangeType"
            >
              <option selected="selected" value="">Choose type</option>
              <option value="1">Outbound call</option>
              <option value="2">Send text</option>
              <option value="3">Send email</option>
              <!-- <option value="4">Appt confirmation</option>
                        <option value="5">Lead notes</option> -->
            </select>
            <div
              class="validate-error-message"
              v-if="$vd.addTask.type.$hasError === true"
            >
              {{ $vd.addTask.$errors.type[0] }}
            </div>
          </div>
          <div
            class="form-group alert-con"
            v-if="addTask.type == 2 || addTask.type == 3"
          >
            <div class="table-footer">
              <label for="inputAddress">Automate Option</label>
              <li class="show-delet">
                <label class="switch"
                  ><input
                    type="checkbox"
                    v-model="addTask.automation"
                    @change="onChnageHandelere($event)"
                    true-value="1"
                    false-value="0" /><span class="slider round"></span
                ></label>
              </li>
            </div>
          </div>
          <div class="form-group" v-if="this.sms">
            <label for="inputAddress2">Select Sms Templates</label>
            <select
              class="form-control js-example-tags"
              v-model="addTask.template"
            >
              <option selected="selected" value="">Choose type</option>
              <option
                :value="template.id"
                v-for="template in sms_templates"
                :key="template.id"
              >
                {{ template.name }}
              </option>
            </select>
          </div>
          <div class="form-group" v-if="this.email">
            <label for="inputAddress2">Select Email Templates</label>
            <select
              class="form-control js-example-tags"
              v-model="addTask.template"
            >
              <option selected="selected" value="">Choose type</option>
              <option
                :value="template.ut_id"
                v-for="template in email_templates"
                :key="template.ut_id"
              >
                {{ template.ut_title }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="inputAddress">Date</label>
            <date-range-picker
              @update="getTasksForAddNew($route.params.id)"
              :min-date="yesterdayDate"
              :locale-data="{ format: 'mmmm dd, yyyy' }"
              v-model="addTask.ufDate"
              :single-date-picker="true"
              :show-dropdowns="true"
              :auto-apply="true"
              :ranges="false"
            ></date-range-picker>
            <!-- <input type="date" class="form-control" placeholder="Pick your date" v-model="addTask.date"> -->
            <div
              class="validate-error-message"
              v-if="$vd.addTask.ufDate.$hasError === true"
            >
              {{ $vd.addTask.$errors.ufDate[0] }}
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Time</label>
              <vue-timepicker
                v-model="addTask.time"
                format="h:mm A"
                :minute-interval="5"
                placeholder="hh:mm A"
              ></vue-timepicker>
            </div>
            <div
              class="validate-error-message"
              v-if="$vd.addTask.time.$hasError === true"
            >
              {{ $vd.addTask.$errors.time[0] }}
            </div>
            <!-- <div class="form-group col-md-4">
                        <label for="inputCity">Time</label>
                        <select class="form-control"  v-model="addTask.tHour">
                            <option value="">hours</option>
                            <option v-for="(n, index) in 12" :key="index" v-bind:value="n">{{n}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputState"></label>
                        <select class="form-control"  v-model="addTask.tMin">
                            <option value="">minutes</option>
                            <option v-for="(n, index) in 60" :key="index" v-bind:value="n-1 | numberStep('2')">{{n-1 | numberStep('2')}}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label for="inputZip"></label>
                        <select class="form-control" v-model="addTask.tType">
                            <option value="am">am</option>
                            <option value="pm">pm</option>
                        </select>
                    </div> -->
          </div>
          <div
            class="form-group"
            v-if="$storage.get('auth').user.user_type == 1"
          >
            <label for="inputAddress2">Assign to</label>
            <select
              class="form-control"
              v-model="addTask.assignTo"
              v-on:change="getTasksForAddNew($route.params.id)"
            >
              <option value="">Select user...</option>
              <option
                v-for="emp in employeesList"
                :key="emp.id"
                :value="emp.id"
              >
                {{ emp.name }}
              </option>
            </select>
            <div
              class="validate-error-message"
              v-if="$vd.addTask.assignTo.$hasError === true"
            >
              {{ $vd.addTask.$errors.assignTo[0] }}
            </div>
          </div>
          <div class="form-group">
            <label for="inputAddress">Name this task</label>
            <textarea
              class="form-control"
              rows="2"
              placeholder="Type here..."
              v-model="addTask.name"
            ></textarea>
            <div
              class="validate-error-message"
              v-if="$vd.addTask.name.$hasError === true"
            >
              {{ $vd.addTask.$errors.name[0] }}
            </div>
          </div>

          <simplebar class="active-meeting" id="myElement2">
            <div class="media" v-for="task in lTasks" :key="task.ta_id">
              <!-- <div
                class="media-body"
                 :class="
                    task.time_status == 'today'
                      ? aptColors[0]
                      : task.time_status == 'past'
                      ? aptColors[1]
                      : aptColors[2]
                  "
              > -->
              <div
                class="media-body"
                :class="
                  task.time_status == 'today'
                    ? aptColors[0]
                    : task.time_status == 'past'
                    ? aptColors[1]
                    : aptColors[2]
                "
              >
                <h5 class="mt-0" style="text-transform: unset">
                  {{ task.ta_name }}
                </h5>
                <p v-if="$storage.get('auth').user.user_type == 1">
                  Assigned to: {{ task.name }}
                </p>
                <p>{{ task.ta_date | formatDate("DD MMM hh:mm A") }}</p>
                <div class="appo-menu">
                  <ul>
                    <li>
                      <a
                        data-toggle="tooltip"
                        title="Completed"
                        :id="'compl' + task.ta_id"
                        class="okay"
                        v-on:click.stop.prevent="
                          updateTaskStatus(task.ta_id, 1, task.time_status)
                        "
                        :class="task.ta_status == 1 ? 'okay-bg' : ''"
                        href="#"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <g clip-path="url(#clip0)">
                            <path
                              d="M0.769569 4.64628C0.574307 4.45102 0.257724 4.45102 0.0624622 4.64628C-0.1328 4.84155 -0.1328 5.15813 0.0624622 5.35339L0.769569 4.64628ZM2.91602 7.49984L2.56246 7.85339C2.75772 8.04865 3.07431 8.04865 3.26957 7.85339L2.91602 7.49984ZM7.8529 3.27006C8.04816 3.0748 8.04816 2.75821 7.8529 2.56295C7.65764 2.36769 7.34106 2.36769 7.14579 2.56295L7.8529 3.27006ZM2.8529 4.64628C2.65764 4.45102 2.34106 4.45102 2.1458 4.64628C1.95053 4.84155 1.95053 5.15813 2.1458 5.35339L2.8529 4.64628ZM4.6458 7.14628C4.45053 7.34155 4.45053 7.65813 4.6458 7.85339C4.84106 8.04865 5.15764 8.04865 5.3529 7.85339L4.6458 7.14628ZM9.93623 3.27006C10.1315 3.0748 10.1315 2.75821 9.93623 2.56295C9.74097 2.36769 9.42439 2.36769 9.22913 2.56295L9.93623 3.27006ZM0.0624622 5.35339L2.56246 7.85339L3.26957 7.14628L0.769569 4.64628L0.0624622 5.35339ZM5.3529 7.85339L9.93623 3.27006L9.22913 2.56295L4.6458 7.14628L5.3529 7.85339ZM3.26957 7.85339L4.31124 6.81172L3.60413 6.10462L2.56246 7.14628L3.26957 7.85339ZM4.31124 6.81172L7.8529 3.27006L7.14579 2.56295L3.60413 6.10462L4.31124 6.81172ZM2.1458 5.35339L3.60413 6.81172L4.31124 6.10462L2.8529 4.64628L2.1458 5.35339Z"
                              fill="#3151A1"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="10" height="10" fill="white" />
                            </clipPath>
                          </defs></svg
                      ></a>
                    </li>
                    <li>
                      <a
                        data-toggle="tooltip"
                        title="Reschedule"
                        href="#"
                        v-on:click.stop.prevent="reScheduleTask(task)"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                        >
                          <g clip-path="url(#clip0)">
                            <path
                              d="M3.41634 1.25C3.41634 0.973858 3.19248 0.75 2.91634 0.75C2.6402 0.75 2.41634 0.973858 2.41634 1.25H3.41634ZM7.58301 1.25C7.58301 0.973858 7.35915 0.75 7.08301 0.75C6.80687 0.75 6.58301 0.973858 6.58301 1.25H7.58301ZM1.66634 2.58333H2.91634V1.58333H1.66634V2.58333ZM2.91634 2.58333H7.08301V1.58333H2.91634V2.58333ZM7.08301 2.58333H8.33301V1.58333H7.08301V2.58333ZM8.33301 9.08333H1.66634V10.0833H8.33301V9.08333ZM1.33301 8.75V4.16667H0.333008V8.75H1.33301ZM1.33301 4.16667V2.91667H0.333008V4.16667H1.33301ZM8.66634 2.91667V4.16667H9.66634V2.91667H8.66634ZM8.66634 4.16667V8.75H9.66634V4.16667H8.66634ZM0.833008 4.66667H9.16634V3.66667H0.833008V4.66667ZM3.41634 2.08333V1.25H2.41634V2.08333H3.41634ZM7.58301 2.08333V1.25H6.58301V2.08333H7.58301ZM1.66634 9.08333C1.48225 9.08333 1.33301 8.93409 1.33301 8.75H0.333008C0.333008 9.48638 0.929962 10.0833 1.66634 10.0833V9.08333ZM8.33301 10.0833C9.06939 10.0833 9.66634 9.48638 9.66634 8.75H8.66634C8.66634 8.9341 8.5171 9.08333 8.33301 9.08333V10.0833ZM8.33301 2.58333C8.5171 2.58333 8.66634 2.73257 8.66634 2.91667H9.66634C9.66634 2.18029 9.06939 1.58333 8.33301 1.58333V2.58333ZM1.66634 1.58333C0.929962 1.58333 0.333008 2.18029 0.333008 2.91667H1.33301C1.33301 2.73257 1.48225 2.58333 1.66634 2.58333V1.58333Z"
                              fill="#3151A1"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0">
                              <rect width="10" height="10" fill="white" />
                            </clipPath>
                          </defs></svg
                      ></a>
                    </li>
                    <li>
                      <a
                        data-toggle="tooltip"
                        title="Missed"
                        :id="'mis' + task.ta_id"
                        v-on:click.stop.prevent="
                          updateTaskStatus(task.ta_id, 2, task.time_status)
                        "
                        :class="task.ta_status == 2 ? 'notok-bg' : ''"
                        class="notok"
                        href="#"
                        ><svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          viewBox="0 0 8 8"
                          fill="none"
                        >
                          <path
                            d="M7.53033 1.53033C7.82322 1.23744 7.82322 0.762563 7.53033 0.46967C7.23744 0.176777 6.76256 0.176777 6.46967 0.46967L7.53033 1.53033ZM0.46967 6.46967C0.176777 6.76256 0.176777 7.23744 0.46967 7.53033C0.762563 7.82322 1.23744 7.82322 1.53033 7.53033L0.46967 6.46967ZM1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L1.53033 0.46967ZM6.46967 7.53033C6.76256 7.82322 7.23744 7.82322 7.53033 7.53033C7.82322 7.23744 7.82322 6.76256 7.53033 6.46967L6.46967 7.53033ZM6.46967 0.46967L0.46967 6.46967L1.53033 7.53033L7.53033 1.53033L6.46967 0.46967ZM0.46967 1.53033L6.46967 7.53033L7.53033 6.46967L1.53033 0.46967L0.46967 1.53033Z"
                            fill="#3151A1"
                          /></svg
                      ></a>
                    </li>
                    <li v-if="task.ta_status == '0'">
                      <a
                        data-toggle="tooltip"
                        title="Delete"
                        :id="'delete' + task.ts_id"
                        v-on:click.stop.prevent="deleteTask(task.ta_id)"
                        href="#"
                        ><img
                          style="width: 11px; margin-top: 5px"
                          src="../../assets/img/Delete.svg"
                      /></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="media not-found" v-if="lTasks.length == 0">
              No Task Found.
            </div>
          </simplebar>

          <!-- <div class="form-group">
                    <label for="inputAddress">Automate tasks</label>
                    <label class="switch">
                    <input type="checkbox" v-model="addTask.automate" >
                    <span class="slider round "></span>
                    </label>
                </div> -->
          <!-- <div class="show-automate" v-if="addTask.automate">
                    <div class="form-row">
                        <div class="form-group col-md-4">
                        <label for="inputCity">Time</label>
                        <select class="form-control"  v-model="addTask.tHour">
                            <option value="">hours</option>
                            <option v-for="(n, index) in 12" :key="index" v-bind:value="n">{{n}}</option>
                        </select>
                        </div>
                        <div class="form-group col-md-4">
                        <label for="inputState"></label>
                        <select class="form-control"  v-model="addTask.tMin">
                            <option value="">minutes</option>
                            <option v-for="(n, index) in 60" :key="index" v-bind:value="n-1 | numberStep('2')">{{n-1 | numberStep('2')}}</option>
                        </select>
                        </div>
                        <div class="form-group col-md-4">
                        <label for="inputZip"></label>
                        <select class="form-control" v-model="addTask.tType">
                            <option value="am">am</option>
                            <option value="pm">pm</option>
                        </select>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="inputAddress2">Add a message to be sent</label>
                        <select class="form-control js-example-tags" v-model="addTask.template">
                            <option selected="selected" value="">Select a template...</option>
                            <option>Appointment Confirmation</option>
                            <option>Are you still in the Market?</option>
                            <option>Birthday Email</option>
                            <option>Birthday Message</option>
                            <option>Close text</option>
                            <option>Congratulations</option>
                        </select>
                    </div>
                </div> -->
        </div>
        <div class="modal-footer app-footer">
          <a
            v-if="this.$store.state.contacted == false"
            href="#"
            class="add-btn"
            v-on:click.stop.prevent="toggleAddTask('hide')"
          >
            Back</a
          >

          <button :disabled="taskloader" type="submit" class="rem-btn">
            <span v-if="taskloader"
              ><span class="spinner-grow spinner-grow-sm"></span>
              Loading...</span
            >
            <span v-if="!taskloader"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Close.svg"
                alt=""
              />
              Add</span
            >
          </button>
        </div>
      </form>
    </div>
    <!-- add-Tasks -->

    <!-- update-Tasks -->
    <div class="add-tasks update-task">
      <div class="modal-header">
        <h5 class="modal-title">Reschedule Task</h5>
        <a
          class="close hide-task"
          data-dismiss="modal"
          aria-label="Close"
          v-on:click.stop.prevent="toggleUpdateTask('hide')"
        >
          <span aria-hidden="true"
            ><img
              class="img-fluid"
              src="../../assets/img/23. Closex.svg"
              alt=""
          /></span>
        </a>
      </div>
      <form v-on:submit.prevent="updateTask">
        <div class="add-body">
          <div class="form-group">
            <label for="inputAddress2">Task type</label>
            <select
              class="form-control js-example-tags"
              @change="onReschecduleTask"
              v-model="updTask.type"
            >
              <option selected="selected" value="">Choose type</option>
              <option value="1">Outbound call</option>
              <option value="2">Send text</option>
              <option value="3">Send email</option>
              <option value="4">Appt confirmation</option>
              <option value="5">Lead notes</option>
            </select>
            <div
              class="validate-error-message"
              v-if="$vd.updTask.type.$hasError === true"
            >
              {{ $vd.updTask.$errors.type[0] }}
            </div>
          </div>
          <div
            class="form-group alert-con"
            v-if="updTask.type == 2 || updTask.type == 3"
          >
            <div class="table-footer">
              <label for="inputAddress">Automate Option</label>
              <li class="show-delet">
                <label class="switch"
                  ><input
                    type="checkbox"
                    v-model="updTask.automation"
                    @change="onChnageupdateHandelere($event)"
                    true-value="1"
                    false-value="0" /><span class="slider round"></span
                ></label>
              </li>
            </div>
          </div>
          <div class="form-group" v-if="this.sms && updTask.automation == 1">
            <label for="inputAddress2">Select Sms Templates</label>
            <select
              class="form-control js-example-tags"
              v-model="updTask.template"
            >
              <option selected="selected" value="">Choose type</option>
              <option
                :value="template.id"
                v-for="template in sms_templates"
                :key="template.id"
              >
                {{ template.name }}
              </option>
            </select>
          </div>
          <div class="form-group" v-if="this.email && updTask.automation == 1">
            <label for="inputAddress2">Select Email Templates</label>
            <select
              class="form-control js-example-tags"
              v-model="updTask.template"
            >
              <option selected="selected" value="">Choose type</option>
              <option
                :value="template.ut_id"
                v-for="template in email_templates"
                :key="template.ut_id"
              >
                {{ template.ut_title }}
              </option>
            </select>
          </div>
          <div class="form-group">
            <label for="inputAddress">Date</label>
            <date-range-picker
              :min-date="yesterdayDate"
              :locale-data="{ format: 'mmmm dd, yyyy' }"
              v-model="updTask.ufDate"
              :single-date-picker="true"
              :show-dropdowns="true"
              :auto-apply="true"
              :ranges="false"
            ></date-range-picker>
            <!-- <input type="date" class="form-control" placeholder="Pick your date" v-model="updTask.date"> -->
            <div
              class="validate-error-message"
              v-if="$vd.updTask.ufDate.$hasError === true"
            >
              {{ $vd.updTask.$errors.ufDate[0] }}
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-md-12">
              <label>Time</label>
              <vue-timepicker
                v-model="updTask.time"
                format="h:mm A"
                name="timepicker"
                :minute-interval="5"
                manual-input
                placeholder="hh:mm A"
              ></vue-timepicker>
             <!-- <vue-timepicker v-model="yourStringValue"  name="timepicker" format="h:mm:ss A"></vue-timepicker> -->
            </div>
            <div
              class="validate-error-message"
              v-if="$vd.updTask.time.$hasError === true"
            >
              {{ $vd.updTask.$errors.time[0] }}
            </div>
            <!-- <div class="form-group col-md-4">
                    <label for="inputCity">Time</label>
                    <select class="form-control"  v-model="updTask.tHour">
                        <option value="">hours</option>
                        <option v-for="(n, index) in 12" :key="index" v-bind:value="n">{{n}}</option>
                    </select>
                    </div>
                    <div class="form-group col-md-4">
                    <label for="inputState"></label>
                    <select class="form-control"  v-model="updTask.tMin">
                        <option value="">minutes</option>
                        <option v-for="(n, index) in 60" :key="index" v-bind:value="n-1 | numberStep('2')">{{n-1 | numberStep('2')}}</option>
                    </select>
                    </div>
                    <div class="form-group col-md-4">
                    <label for="inputZip"></label>
                    <select class="form-control" v-model="updTask.tType">
                        <option value="am">am</option>
                        <option value="pm">pm</option>
                    </select>
                    </div> -->
          </div>
          <div
            class="form-group"
            v-if="$storage.get('auth').user.user_type == 1"
          >
            <label for="inputAddress2">Assign to</label>
            <select class="form-control" v-model="updTask.assignTo">
              <option value="">Select user...</option>
              <option
                v-for="emp in employeesList"
                :key="emp.id"
                :value="emp.id"
              >
                {{ emp.name }}
              </option>
            </select>
            <div
              class="validate-error-message"
              v-if="$vd.updTask.assignTo.$hasError === true"
            >
              {{ $vd.updTask.$errors.assignTo[0] }}
            </div>
          </div>
          <div class="form-group">
            <label for="inputAddress">Name this task</label>
            <textarea
              class="form-control"
              rows="2"
              placeholder="Type here..."
              v-model="updTask.name"
            ></textarea>
            <div
              class="validate-error-message"
              v-if="$vd.updTask.name.$hasError === true"
            >
              {{ $vd.updTask.$errors.name[0] }}
            </div>
          </div>
        </div>
        <div class="modal-footer app-footer">
          <a
            href="#"
            class="add-btn"
            v-on:click.stop.prevent="toggleUpdateTask('hide')"
          >
            Back</a
          >

          <button :disabled="taskloader" type="submit" class="rem-btn">
            <span v-if="taskloader"
              ><span class="spinner-grow spinner-grow-sm"></span>
              Loading...</span
            >
            <span v-if="!taskloader"
              ><img
                class="img-fluid"
                src="../../assets/img/23. Close.svg"
                alt=""
              />
              Update</span
            >
          </button>
        </div>
      </form>
    </div>
    <!-- update-Tasks -->
  </div>
  <!-- Tasks-area -->
</template>

<script>
import axios from "axios";
import VueDaval from "vue-daval";
import $ from "jquery";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import VueTimepicker from "vue2-timepicker/src/vue-timepicker.vue";
export default {
  name: "Tasks",
  props: ["defaultAssignTo", "employeesList", "lead_status"],
  components: {
    simplebar,
    DateRangePicker,
    VueTimepicker,
  },
  mixins: [VueDaval],
  data() {
    return {
      yourStringValue: '3:mm:05 A',
      taskloader: false,
      sms: false,
      email: false,
      sms_templates: [],
      email_templates: [],
      automatdd: false,
      aptColors: ["green-meeting", "red-meeting", "blue-meeting"],
      yesterdayDate: moment().subtract(1, "day").format("YYYY-MM-DD"),
      lTasks: [],
      addTasks: [],
      tskDate: { startDate: new Date() },
      addTask: {
        automate: false,
        date: "",
        ufDate: { startDate: new Date(), endDate: new Date() },
        time: "",
        assignTo: this.$props.defaultAssignTo,
        name: "",
        type: "",
        page: this.$route.name,
        automation: "",
      },
      updTask: {
        id: false,
        date: "",
        ufDate: { startDate: new Date(), endDate: new Date() },
        time: "",
        assignTo: "",
        name: "",
        type: "",
        page: this.$route.name,
        template: "",
        automation: "",
      },
    };
  },
  vdRules: {
    addTask: {
      ufDate: { required: true },
      name: { required: true },
      type: { required: true },
      assignTo: { required: true },
      time: {
        checkAlias: (alias) => {
          if (
            alias != "" &&
            alias != undefined &&
            alias != "undefined" &&
            alias != null
          ) {
            if (alias.indexOf("hh") > -1) {
              return "Invalid Time. Please select complete time.";
            } else if (alias.indexOf("mm") > -1) {
              return "Invalid Time. Please select complete time.";
            } else if (alias.indexOf("AM") > -1) {
              return true;
            } else if (alias.indexOf("PM") > -1) {
              return true;
            } else {
              return "Invalid Time. Please select complete time.";
            }
          } else {
            return true;
          }
        },
      },
      //tHour:{required: true},tMin:{required: true}, tDtype: {required: true},template:{required: true},
    },
    updTask: {
      ufDate: { required: true },
      name: { required: true },
      type: { required: true },
      assignTo: { required: true },
      time: {
        checkAlias: (alias) => {
          if (
            alias != "" &&
            alias != undefined &&
            alias != "undefined" &&
            alias != null
          ) {
            if (alias.indexOf("hh") > -1) {
              return "Invalid Time. Please select complete time.";
            } else if (alias.indexOf("mm") > -1) {
              return "Invalid Time. Please select complete time.";
            } else if (alias.indexOf("AM") > -1) {
              return true;
            } else if (alias.indexOf("PM") > -1) {
              return true;
            } else {
              return "Invalid Time. Please select complete time.";
            }
          } else {
            return true;
          }
        },
      },
    },
  },
  mounted() {},
  watch: {
    defaultAssignTo: function (rt) {
      this.addTask.assignTo = rt;
      this.getTasksForAddNew(this.$route.params.id);
    },
  },
  created() {
    let vm = this;
    vm.getTasks();
    vm.getSmsTemplates();
    vm.getTemplates();
    this.$root.$on("action", function (action) {
      if (action == "getalltask") {
        vm.getTasks();
      }
    });
    // if(this.$route.name == 'Dashboard'){
    //     this.getTasks(0)
    // }else{
    //     this.getTasks(this.$route.params.id)
    // }
  },
  methods: {
    onChangeType() {
      if (this.addTask.automation == 1) {
        if (this.addTask.type == 2) {
          this.sms = true;
          this.email = false;
        } else if (this.addTask.type == 3) {
          this.email = true;
          this.sms = false;
        } else {
          this.email = false;
          this.sms = false;
        }
      }
    },
    onReschecduleTask() {
      if (this.updTask.type == 2) {
        this.sms = true;
        this.email = false;
      } else if (this.updTask.type == 3) {
        this.email = true;
        this.sms = false;
      } else {
        this.email = false;
        this.sms = false;
      }
    },
    getSmsTemplates() {
      axios
        .get("get_smstemplates", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.sms_templates = res.data.success;
            let vm = this;
            setTimeout(() => {
              if (vm.sms_templates.length > 0) {
                vm.temp_ = vm.sms_templates[0];
              }
              this.loaded = true;
            }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTemplates() {
      axios
        .get("get_templates/1", {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.email_templates = res.data.success;
            let vm = this;
            setTimeout(() => {
              if (vm.email_templates.length > 0) {
                vm.temp_ = vm.email_templates[0];
              }
              this.loaded = true;
            }, 500);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onChnageHandelere(event) {
      if (event.target.checked) {
        this.addTask.automation = 1;
        if (this.addTask.type == 2) {
          this.sms = true;
        }
        if (this.addTask.type == 3) {
          this.email = true;
        }
      } else {
        this.addTask.automation = 0;
        this.sms = false;
        this.email = false;
      }
    },
    onChnageupdateHandelere(event) {
      if (event.target.checked) {
        this.updTask.automation = 1;
        if (this.updTask.type == 2) {
          this.sms = true;
        }
        if (this.updTask.type == 3) {
          this.email = true;
        }
      } else {
        this.updTask.automation = 0;
        this.sms = false;
        this.email = false;
      }
    },
    openModal(id) {
      this.$root.$emit("action", "taskoutboundcall", id);
    },
    deleteTask(id) {
      axios
        .get("delete_task/" + id, {
          headers: {
            Authorization: "Bearer " + this.$storage.get("auth").token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.$root.$emit("action", "new_activity");
            $(".tooltip").tooltip("hide");
            this.getTasks();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateTaskStatus(id, status, tstatus) {
      if (tstatus != "past") {
        axios
          .post(
            "update_task_status",
            { id: id, status: status, lead_id: this.$route.params.id },
            {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            }
          )
          .then((res) => {
            if (res.data.success) {
              if (status == 1) {
                $("#compl" + id).addClass("okay-bg");
                $("#mis" + id).removeClass("notok-bg");
              } else if (status == 2) {
                $("#compl" + id).removeClass("okay-bg");
                $("#mis" + id).addClass("notok-bg");
              }
              this.$root.$emit("action", "new_activity");
              this.getTasks();
              if (status == 1) {
                this.$root.$emit("action", "openCallModal");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    reScheduleTask(tsk) {
      console.log(tsk);
        //updateApt: {id:false,date:'',ufDate:{},tHour:'',tMin:'',tType:'am',assignTo:'',name:'',type:'',page:this.$route.name}
        this.updTask.id = tsk.ta_id;
        this.updTask.name = tsk.ta_name;
        this.updTask.assignTo = tsk.ta_created_for;
        this.updTask.type = tsk.ta_task_type;
        this.updTask.ufDate.startDate =  moment(tsk.ta_date).format('MMMM DD, YYYY');
        this.updTask.ufDate.endDate =  moment(tsk.ta_date).format('MMMM DD, YYYY');
        this.updTask.time =  moment(tsk.ta_date).format('h:mm A');
        this.updTask.template = tsk.ta_template;
        this.updTask.automation = tsk.ta_automated;
        if (tsk.ta_task_type == 2) {
          this.sms = true;
          this.email = false;
        } else if (tsk.ta_task_type == 3) {
          this.email = true;
          this.sms = false;
        } else {
          this.sms = false;
          this.email = false;
        }
        this.toggleUpdateTask("show");
      
    },
    updateTask() {
      this.taskloader = true;
      if (this.$route.params.id) {
        this.updTask.lead_id = this.$route.params.id;
      }

      this.$vd.updTask
        .$validate()
        .then(() => {
          let startDate = this.updTask.ufDate.startDate;
          this.updTask.date = moment(new Date(startDate)).format("YYYY-MM-DD");
          //console.log(this.updTask);
          axios
            .post("update_task", this.updTask, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                this.taskloader = false;
                this.toggleUpdateTask("hide");
                this.updTask = {
                  id: false,
                  date: "",
                  ufDate: { startDate: new Date(), endDate: new Date() },
                  time: "",
                  assignTo: "",
                  name: "",
                  type: "",
                  page: this.$route.name,
                };
                this.getTasks();
                this.$root.$emit("action", "new_activity");
              }
            })
            .catch((err) => {
              this.taskloader = false;
              console.log(err);
            });
        })
        .catch(() => {
          this.taskloader = false;
          console.log(this.$vd.updTask.$errors);
        });
    },
    formatAMPM(date) {
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    },
    getTasks(by = "") {
      if (by == "date") {
        this.tskDate.date = moment(new Date(this.tskDate.startDate)).format(
          "YYYY-MM-DD"
        );
      } else {
        this.tskDate.date = "";
      }

      axios
        .post(
          "tasks/" + this.$route.params.id,
          { tskDate: this.tskDate.date },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.lTasks = data.success;
            setTimeout(() => {
              $('[data-toggle="tooltip"]').tooltip();
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTasksForAddNew(id) {
      var startDate = moment(new Date(this.addTask.ufDate.startDate)).format(
        "YYYY-MM-DD"
      );
      axios
        .post(
          "tasks/" + id,
          { tskDate: startDate, tskUser: this.addTask.assignTo },
          {
            headers: {
              Authorization: "Bearer " + this.$storage.get("auth").token,
            },
          }
        )
        .then((res) => {
          if (res.data.success) {
            const data = res.data;
            this.addTasks = data.success;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    toggleAddTask(action) {
      if (action == "show") {
        $(".add-task").addClass("show-add-tasks");
      } else if (action == "hide") {
        $(".add-task").removeClass("show-add-tasks");
      }
    },
    toggleUpdateTask(action) {
      if (action == "show") {
        $(".update-task").addClass("show-add-tasks");
      } else if (action == "hide") {
        $(".update-task").removeClass("show-add-tasks");
      }
    },
    addNewTask() {
      this.taskloader = true;
      if (this.$route.params.id) {
        this.addTask.lead_id = this.$route.params.id;
      }
      let startDate = this.addTask.ufDate.startDate;
      this.addTask.date = moment(new Date(startDate)).format("YYYY-MM-DD");
      this.$vd.addTask
        .$validate()
        .then(() => {
          //console.log(this.addTask);
          axios
            .post("add_task", this.addTask, {
              headers: {
                Authorization: "Bearer " + this.$storage.get("auth").token,
              },
            })
            .then((res) => {
              if (res.data.success) {
                //contacted modal
                console.log(this.$store.state.lostlead);
                if (this.$store.state.contacted) {
                  this.$store.commit("updateContacted", false);
                }
                if (this.$store.state.lostlead != 7) {
                  this.$root.$emit("action", "update_lead_status", 2);
                }

                this.taskloader = false;
                this.toggleAddTask("hide");
                this.addTask = {
                  automate: false,
                  date: "",
                  ufDate: { startDate: new Date(), endDate: new Date() },
                  time: "",
                  assignTo: this.$props.defaultAssignTo,
                  name: "",
                  type: "",
                  page: this.$route.name,
                  automation: 0,
                };
                this.sms = false;
                this.email = false;
                $(".add-task .clear-btn").trigger("click");
                this.getTasks(this.$route.params.id);
                this.$root.$emit("action", "new_activity");
                this.$root.$emit(
                  "action",
                  "update_touch_status",
                  res.data.lead_touch_resp_time
                );
              }
            })
            .catch((err) => {
              this.taskloader = false;
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          this.taskloader = false;
        });
    },
  },
};
</script>

<style scoped>
.tasks-area .vue-daterange-picker {
  width: 100% !important;
}
</style>
